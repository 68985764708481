import { ethers } from "ethers";
import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import erc20ABI from '../contracts/erc20.json'
import TokenSelect from "./TokenSelect"


//needsapproval no updating after approvaltx?

const CreateLock = ({ signer, chastityContract, addTxToList, ethMode, txList, errorVMAlert}) => {

    const [lockAmt, setLockAmt] = useState(0)
    const [lockDays, setLockDays] = useState(0)
    const [panicBool, setPanicBool] = useState(true);
    const [tokenAddress, setTokenAddress] = useState('0x7ceb23fd6bc0add59e62ac25578270cff1b9f619');
    const [tokenDecimals, setTokenDecimals] = useState(18)
    const [tokenName, setTokenName] = useState('')
    const [approvedAmt, setApprovedAmt] = useState(ethers.BigNumber.from('0'))
    const [needsApproval, setNeedsApproval] = useState('')
    const [unlimitedApproval, setsUnlimitedApproval] = useState(false)
    const [ethOrTokenBalance, setEthOrTokenBalance] = useState(0)
    const [tokenTotalSupply, setTokenTotalSupply] = useState(0)

    const handleCreateOrApprove = (event) => {
        event.preventDefault();
        //checks approval status and sends approve tx for unlimited approvals (if in tokenmode)
        if (needsApproval && !ethMode) {
            let amountToApprove
            if (unlimitedApproval) amountToApprove = ethers.constants.MaxUint256
            else amountToApprove = ethers.utils.parseUnits(lockAmt, tokenDecimals)
            approveTokens(chastityContract.address, amountToApprove)
        }
        //if approval amt >= desired lock amt, it allows you to do the create lock
        else {
            let lockAmtString = lockAmt.toString()
            let now = dayjs()
            let parsedDate = now.add((lockDays * 24), 'hours').unix()
            ethMode ? createEthLock(lockAmtString, parsedDate, panicBool) : createTokenLock(tokenAddress, lockAmtString, parsedDate, panicBool, tokenDecimals)
        }
    }

    const approveTokens = async (spender, amount) => {
        try {
        const tokenContract = new ethers.Contract(tokenAddress, erc20ABI, signer)
        let approval = await tokenContract.approve(spender, amount)
        const confirm = await approval.wait()
        confirm && addTxToList(confirm.transactionHash)
        console.log(confirm);
        console.log('approval done tx: ', confirm.transactionHash);
        return confirm
    }
    catch (err) {
        errorVMAlert(err)
     }
    }

    const createEthLock = async (ethLockAmt, unlockTime, panicButton) => {
        try {
        let overrides = { value: ethers.utils.parseEther(ethLockAmt) }
        let newlock = await chastityContract.createLock(unlockTime, panicButton, overrides)
        const confirm = await newlock.wait()
        confirm && addTxToList(confirm.transactionHash)
        console.log(confirm);
        return confirm
    }
    catch (err) {
        errorVMAlert(err)
     }
    }

    const createTokenLock = async (tokenAddress, tokenLockAmt, unlockTime, panicButton, decimals) => {
        try {
        let parsedAmt = ethers.utils.parseUnits(tokenLockAmt, decimals)
        let newlock = await chastityContract.createTokenLock(tokenAddress, parsedAmt, unlockTime, panicButton)
        const confirm = await newlock.wait()
        confirm && addTxToList(confirm.transactionHash)
        console.log(confirm);
        return confirm
    }
    catch (err) {
        errorVMAlert(err)
     }
    }

    const maxEthOrTokens = () => {
        (ethMode) ? setLockAmt(ethers.utils.formatUnits(ethOrTokenBalance, tokenDecimals) - 0.05) : setLockAmt(ethers.utils.formatUnits(ethOrTokenBalance, tokenDecimals))
    }

    useEffect(() => {
        const tokenContract = new ethers.Contract(tokenAddress, erc20ABI, signer)
        const getAddress = async (signerOrWallet) => {
            let address = await signerOrWallet.getAddress()
            return address
        }


        const getTokenDetails = async (address) => {
            try {
                // console.log('trying to get token details');
                setTokenDecimals(await tokenContract.decimals())
                setTokenName(await tokenContract.symbol())
                setEthOrTokenBalance(await tokenContract.balanceOf(address))
                setTokenTotalSupply(await tokenContract.totalSupply())
            }
            catch (err) {
                console.log(err);
                setTokenDecimals(18)
                setTokenName('no token at this address')
                setEthOrTokenBalance(0)
                setTokenTotalSupply(0)
            }
        }

        const getEthBalance = async () => {
            try {
                setEthOrTokenBalance(await signer.getBalance())
                setTokenDecimals(18)
            }
            catch (err) {
                console.log(err);
                setEthOrTokenBalance(0)
            }
        }

        const checkApprovalAmount = async (address) => {
            console.log('doing check approval amt');
            try {
                setApprovedAmt(await tokenContract.allowance(address, chastityContract.address))
            }
            catch (err) {
                console.log(err);
                setApprovedAmt(ethers.BigNumber.from('0'))
            }
        }
        let metamaskAddress = getAddress(signer)
        if (!ethMode) {
            getTokenDetails(metamaskAddress)
            checkApprovalAmount(metamaskAddress)
        }
        else {
            getEthBalance()
        }
    }, [tokenAddress, lockAmt, signer, txList])

    useEffect(() => {

        console.log('doing check approval boolean');

        if (lockAmt) {
            (ethers.utils.parseUnits(lockAmt.toString(), tokenDecimals).gt(approvedAmt)) ? setNeedsApproval(true) : setNeedsApproval(false)
        }
        else {
            setNeedsApproval('error')
        }

    }, [approvedAmt]);

    return (
        <div className="halfbox w-2/5">
            <form className="formstyle" onSubmit={handleCreateOrApprove} autoComplete="off">

                {!ethMode && <>
                    <TokenSelect setTokenAddress={setTokenAddress} tokenAddress={tokenAddress} signer={signer} tokenName={tokenName} />
                </>}
                <div className="maxtokens" onClick={maxEthOrTokens}><span className="cursor-pointer">
                    Balance: {ethers.utils.formatUnits(ethOrTokenBalance, tokenDecimals).toString()}
                </span></div>
                <div className="has-tooltip">
                    Lock Amount:
                <input className="textinput" type="text" name="amount" value={lockAmt} onChange={e => setLockAmt(e.target.value)} />
                    <span className='tooltip'>This is how much Matic or tokens you will lock</span></div><br />
                <div className="maxtokens">
                    {"Unlock Date & Time: " + dayjs().add((lockDays * 24), 'hours').format('DD-MMM-YY HH:mm')}
                </div>
                <div className="has-tooltip">
                    Days to Lock:
                    <input className="textinput" type="text" name="dayslocked" value={lockDays} onChange={e => setLockDays(e.target.value)} />
                    <span className='tooltip'>This is how many days you will be locked for (decimals allowed)</span></div><br />
                <div className="has-tooltip">
                    Allow Panic Unlock (by Keyholder)
                    <input className="radiocheck" type="checkbox" checked={panicBool} onChange={e => setPanicBool(e.target.checked)} />
                    <span className='tooltip'>Allows the contract owner to unlock (but not withdraw) your funds before your unlock date</span></div>
                <br />
                {((needsApproval) && !ethMode) 
                ? <button className="btn mt-6" type="submit">Approval</button> 
                : <button className="btn mt-6" type="submit">Create Lock</button>}
                <p />
            </form>


            {/* <input className="textinput" type="text" name="tokens" id="tokens" />  */}
            {/* <datalist id="listOfTokens"> { tokenList && tokenList.tokens.map(tokenObject => ( <option key={tokenObject.symbol} value={tokenObject.symbol} /> )) } </datalist> */}
            {/* <p />
            Form Inputs: <br />
            LockAmt: {lockAmt} <br />
            LockDays: {lockDays} <br />
            panicBool: {panicBool.toString()} <br />
            {!ethMode && <>

            ethOrTokenBalance: {ethers.utils.formatUnits(ethOrTokenBalance, tokenDecimals).toString()} <br />
            tokenAddress: {tokenAddress.toString()} <br />
            tokenName: {tokenName.toString()} <br />
            tokenDecimals: {tokenDecimals.toString()} <br />
            approvedAmt: {(approvedAmt.gte(tokenTotalSupply)) ? 'unlimited' : ethers.utils.formatUnits(approvedAmt, tokenDecimals)} <br />
            needsApproval: {needsApproval.toString()} <br />
            </>} */}

            <br />

        </div>
    )
}

export default CreateLock


