import React, { useState, useEffect } from 'react'

const GetOwner = ({chastityContract}) => {

    const [owner, setOwner] = useState('...')

    useEffect(() => {
        const getOwner = async () => {
            let contractOwner = await chastityContract.owner()
            setOwner(contractOwner)
        }
        getOwner()
    }, [owner, chastityContract]);

    return (
        <div className="text-center mb-4">
            The keyholder is {owner}... If you have panic unlock turned on, you can beg the keyholder for release on Twitter @astr0bas3d.
        </div>
    )
}

export { GetOwner }