import { ethers } from "ethers";
import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import erc20ABI from '../contracts/erc20.json'

const WithdrawLock = ({signer, chastityContract, txList, unlockToggle, ownerWallet, addTxToList, ethMode, web3Address, errorVMAlert}) => {
    const [locksLength, setLocksLength] = useState('...')
    const [lockDetails, setLockDetails] = useState([])
    const [withdrawLockNum, setWithdrawLockNum] = useState(0);

    const handleWithdrawal = (event) => {
        event.preventDefault();
            ethMode ? withdrawEth(withdrawLockNum) : withdrawTokens(withdrawLockNum)
    } 
    
    const withdrawEth = async (lockNum) => {
        try {
        let ethWithdrawn = await chastityContract.withdrawEth(lockNum)
        const confirm = await ethWithdrawn.wait()
        confirm && addTxToList(confirm.transactionHash)
        console.log(confirm);
        return confirm
    }
    catch (err)
    {
        errorVMAlert(err)
    }
    }

    const withdrawTokens = async (lockNum) => {
        try {
        let tokensWithdrawn = await chastityContract.withdrawTokens(lockNum)
        const confirm = await tokensWithdrawn.wait()
        confirm && addTxToList(confirm.transactionHash)
        console.log(confirm);
        return confirm
    }
        catch (err)
        {
            errorVMAlert(err)
        }
    }

    const unlockChastity = async (unlockAddress, lockNum) => {
        let unlock
        if (ethMode) {
            unlock = await chastityContract.connect(ownerWallet).unlockEth(unlockAddress, lockNum)
        }
        else {
            unlock = await chastityContract.connect(ownerWallet).unlockTokens(unlockAddress, lockNum)
        }
        const confirm = await unlock.wait()
        confirm && addTxToList(confirm.transactionHash)
        console.log(confirm);
        return confirm
    }

    // const testUnlock = async () => {
    //     let unlockAddress = ''
    //     let testLockNum = withdrawLockNum
    //     let unlockdone = await unlockChastity(unlockAddress, testLockNum)
    // }

    useEffect(() => {
        const getLocks = async (ethMode) => {
            console.log('Effect is running for tx ', txList[txList.length - 1]);    
            let metamaskAddress = web3Address
            let chastityLocksLength
            if (ethMode) {
                chastityLocksLength   = await chastityContract.locksLength(metamaskAddress)
            }
            else {
                chastityLocksLength   = await chastityContract.tokenLocksLength(metamaskAddress)
            }
            setLocksLength(chastityLocksLength.toString())
            let locksArray = []
            for (let i = 0; i < chastityLocksLength; i++) {
                let lockdetail
                let now = dayjs()
                if (ethMode) {
                    lockdetail = await chastityContract.lockDetails(metamaskAddress, i)
                    let isWithdrawable = (lockdetail[1] <= now.unix())
                    locksArray[i] = { lockNum: i, ethLocked: lockdetail[0].toString(), unlockTime: lockdetail[1].toString(), panicButton: lockdetail[2].toString(), isWithdrawable }
                }
                else {
                    lockdetail = await chastityContract.tokenLockDetails(metamaskAddress, i)
                    let isWithdrawable = (lockdetail[2] <= now.unix())
                    const tokenContract = new ethers.Contract(lockdetail[0], erc20ABI, signer)
                    let tokenSymbol = await tokenContract.symbol()
                    locksArray[i] = { lockNum: i, tokenAddress: lockdetail[0].toString() ,ethLocked: lockdetail[1].toString(), unlockTime: lockdetail[2].toString(), panicButton: lockdetail[3].toString(), isWithdrawable, tokenSymbol }
                }
            }
            setLockDetails(locksArray)
        }
        setTimeout(() => {
            getLocks(ethMode)
        }, 500);
        
    }, [signer,chastityContract,txList,ethMode]);


    return (
        <div className="halfbox w-3/5">
                        <p />
            <div className="mb-4">This wallet currently has {locksLength} lockups.</div>
            
            {/* {withdrawLockNum} */}

    <table className="mb-4 table-auto mx-auto">
    <tbody className="text-xs">
    <tr className="mb-4">
        <th className="tablecell"></th>
        <th className="tablecell">Lock No.</th>
        {(!ethMode) && (<th>Token</th>)}
        <th className="tablecell">Amount</th>
        <th className="tablecell">Unlock Time</th>
        <th className="tablecell">Panic Button</th>
        <th className="tablecell">Withdrawable</th>
    </tr>
            {/* <form className="formstyle" onSubmit={handleWithdrawal}> */}
            {lockDetails.map((lock, i) => {
                    return <tr key={i} className="mb-4 hover:bg-gray-600 cursor-pointer" onClick={() => setWithdrawLockNum(lock.lockNum)}>
                        <td className="tablecell"><input type="radio" className="radiocheck rounded-full" value={lock.lockNum} id={lock.lockNum} 
                        onChange={() => setWithdrawLockNum(lock.lockNum)} checked={lock.lockNum === withdrawLockNum}/></td>
                        <td className="tablecell">{lock.lockNum + 1}</td>
                        {(!ethMode) && (<td className="tablecell">{lock.tokenSymbol}</td>)}
                        <td className="tablecell">{ethers.utils.formatEther(lock.ethLocked)}</td>
                        <td className="tablecell">{dayjs.unix(lock.unlockTime).format('DD-MMM-YY HH:mm')}</td>
                        <td className="tablecell"> {lock.panicButton}</td>
                        <td className="tablecell">{lock.isWithdrawable.toString()}</td>
                        </tr>

                })}
            {/* </form> */}
            </tbody></table>
            <button className="btn" type="button" onClick={handleWithdrawal}>Withdraw Lock Number {withdrawLockNum + 1}</button>
            <br />

{/* {unlockToggle && <button className="btn mt-4" onClick={testUnlock}> Unlock Lock: {withdrawLockNum} </button>} */}
        </div>
    )
}

export default WithdrawLock