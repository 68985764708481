import React from "react";
import splash from '../media/connect-page-image.png'


export function WrongChain({ chainObject }) {

  const onChangeChain = async () => {
    try {
      // Will open the MetaMask UI
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: chainObject,
      })
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="text-grey-200 text-xs w-1/3 mx-auto px-8 py-12 text-center">
      <h2 className="text-2xl text-red-500 mb-4">YOU'VE BEEN NAUGHTY, YOU ESCAPED YOUR CHAIN AGAIN</h2>
      <img src={splash} className="h-72 mx-auto mb-4" />
      <div className="text-bold text-base mb-4">Stop misbehaving, fix your mistake and get back into the right chains.
          Yes, we're going to lock you up nice and good on the Ethereum Chain...</div>
      <br /><button onClick={onChangeChain} className="btn text-lg">Re-Chain Yourself</button>
    </div>
  )
}

const decimalToHex = (decimal) => {
  let hex = '0x' + decimal.toString(16)
  return hex
}

