import React from "react";
import splash from '../media/connect-page-image.png'


export function ConnectModal() {
    return (
        <div className="text-grey-200 text-xs w-1/3 mx-auto px-8 py-12 text-center">
            <h2 className="text-2xl text-red-500 mb-4">WARNING: ADULTS ONLY</h2>
            <img src={splash} className="h-72 mx-auto mb-4"/>
    <div className="mb-4">By proceeding any further to this adults-only-shitcoin-locking website, you agree to the following terms and conditions:</div>
    <ol className="list-decimal space-y-2">
    <li>
        You certify that you are 18 years of age or older, and are not offended by sexually explicit imagery, and that you are <strong>BASED</strong>
    </li>
    <li>
        You agree that you will not permit any person(s) under 18 years of age to have access to any of the materials contained within this site.
    </li>
    <li>
        You agree that your shitcoins (or eth) could be locked away forever if you interact with this contract
    </li>
    <li>
        You agree that <strong>IF</strong> you choose to allow for emergency unlocks, only the keyholder (creator of this contract) can unlock your funds for you in an emergency.
    </li></ol>
            <br /><button onClick={onClickConnect} className="btn text-lg">Connect</button>
        </div>
    )
}

const onClickConnect = async () => {
    try {
        // Will open the MetaMask UI
        // You should disable this button while the request is pending!
        await window.ethereum.request({ method: 'eth_requestAccounts' });
    } catch (error) {
        console.error(error);
    }
}