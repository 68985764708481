import { ethers } from "ethers";
import React, { useState, useEffect } from 'react'
import chastityArtifact from '../contracts/Chastity.json'
import contractaddress from '../contracts/contract-address.json'
import { GetOwner } from './ReadChain'
import CreateLock from './CreateLock'
import WithdrawLock from './WithdrawLock'
import logo from '../media/based-chastity-logo.png'
import { NoWalletDetected } from "./NoWalletDetected";
import { ConnectModal } from "./ConnectModal";
import { WrongChain } from "./WrongChain"
require('dotenv').config()

const Dapp = () => {
    const [txList, setTxList] = useState([])
    const [ethMode, setEthMode] = useState(true)
    const [web3Address, setWeb3Address] = useState()
    const [chainID, setChainID] = useState()

    //if no metamask installed, show error telling them to install metamask

    if (window.ethereum === undefined) {
        return <NoWalletDetected />;
    }

    const chainrequest = window.ethereum.request({ method: 'eth_chainId' })
    const addressrequest = window.ethereum.request({ method: 'eth_accounts' })
    let localOrLive = process.env.REACT_APP_LOCAL_OR_LIVE

    let chainObject
    let chastityAddress

    if (localOrLive === 'local') {
        chainObject = [{
            chainId: '0x539',
            chainName: 'Local Chain',
            nativeCurrency: {
                name: 'ETH',
                symbol: 'ETH',
                decimals: 18,
            },
            rpcUrls: ['https://localhost:8545/'],
            blockExplorerUrls: ['https://etherscan.io/'],
        }]
        chastityAddress = contractaddress.Chastity
    }
    else {
        chainObject = [{
            chainId: '0x89',
            chainName: 'Polygon',
            nativeCurrency: {
                name: 'Matic',
                symbol: 'Matic',
                decimals: 18,
            },
            rpcUrls: ['https://rpc-mainnet.maticvigil.com'],
            blockExplorerUrls: ['https://explorer-mainnet.maticvigil.com/'],
        }]
        chastityAddress = '0x5E8D9b4DCddCEa41FDe13006690d562c5E1B5b78'
    }

    // if chainID is not the network we want or if null, show error for chainID

    chainrequest.then((response) => {
        setChainID(response)
    })

    window.ethereum.on("chainChanged", (networkID) => {
        setChainID(networkID)
    })

    if (chainID !== chainObject[0].chainId) {
        return <WrongChain chainObject={chainObject} />
    }

    // if there is no address tied to the metamask (i.e if locked or not connected) show connect screen

    addressrequest.then((response) => {
        setWeb3Address(response[0])
    })

    window.ethereum.on('accountsChanged', (newAddress) => {
        console.log('account changed', newAddress[0]);
        setWeb3Address(newAddress[0])
    })



    if (!web3Address) {
        return <ConnectModal />
    }

    const chastityABI = chastityArtifact.abi
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    // const rpcprovider = new ethers.providers.JsonRpcProvider('http://127.0.0.1:8545/')
    // const localhostownerkey = PrivateKeyEnvironmentVariableHere
    // const ownerWallet = new ethers.Wallet(localhostownerkey, rpcprovider)
    const ownerWallet = ''
    const signer = provider.getSigner()
    const chastityContract = new ethers.Contract(chastityAddress, chastityABI, signer)

    const addTxToList = (tx) => {
        setTxList(txList.concat(tx))
        console.log('list of TX:', txList);
    }

    const errorVMAlert = (err) => {
        err.data && alert(err.data.message.replace("VM Exception while processing transaction: revert ", ""))
        console.log(err.message)
    }

    return (
        <div className="px-8 py-4 max-w-7xl mx-auto">
            <div className="flex mb-4">
                <div className="w-1/4"></div>
                <div className="text-center w-2/4">
                    <h2 className="text-3xl text-red-500 uppercase mb-4">Based Chastity</h2>
                    <img src={logo} className="mx-auto mb-4 h-72" />
                    {ethMode ? 'You are on Matic Mode ' : 'You are on Token Mode '}
                    <input className="radiocheck" type="checkbox" checked={ethMode} onChange={e => setEthMode(e.target.checked)} />
                </div>
                <div className="w-1/4"></div>
            </div>
            <GetOwner chastityContract={chastityContract} />
            <div className="flex max-w-5xl mx-auto space-x-5">
                <CreateLock signer={signer} chastityContract={chastityContract} addTxToList={addTxToList}
                    ethMode={ethMode} txList={txList} errorVMAlert={errorVMAlert} />

                <WithdrawLock
                    signer={signer} chastityContract={chastityContract} txList={txList} web3Address={web3Address}
                    unlockToggle={false} ownerWallet={ownerWallet} addTxToList={addTxToList} ethMode={ethMode}
                    errorVMAlert={errorVMAlert}
                />
            </div>
        </div>
    )
}
export default Dapp