import React, { useState, useEffect } from "react";
import erc20ABI from '../contracts/erc20.json'
import { ethers } from "ethers";



const TokenSelect = ({ tokenAddress, setTokenAddress, tokenName, signer }) => {

  const [tokenList, setTokenList] = useState()
  const [showModal, setShowModal] = useState(false);
  const [searchAddress, setSearchAddress] = useState('')
  const [loading, setLoading] = useState(false)

  const setTokenAndClose = (tokenaddress) => {
    setTokenAddress(tokenaddress)
    setShowModal(false)
  }

  const findAndAddToken = (e) => {
    let input = e.target.value
    setSearchAddress(input)
    if (input.slice(0, 2) === '0x') {
      setLoading(true)
        const tokenContract = new ethers.Contract(input, erc20ABI, signer)
        tokenContract.symbol().then((response) => {
          setTokenList([{ address: input, symbol: response, highlight:true }].concat(tokenList))
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          console.log(err);
          console.log('no such token (or something else is broken)')
        })
    }
    else console.log('invalid address u cuck')
  }

  useEffect(() => {

    const fetchTokens = async () => {
      let response = await fetch('https://unpkg.com/quickswap-default-token-list@1.0.59/build/quickswap-default.tokenlist.json')
      let jsonData = await response.json()
      let options = []
      jsonData.tokens.map((token) => {
        let option = { address: token.address, symbol: token.symbol, highlight: false }
        options.push(option)
      })
      setTokenList(options)
    }

    fetchTokens()

  }, []);


  return (<>
  <div className="maxtokens">Token Symbol: {tokenName.toString()}</div>
  <div className="has-tooltip">Token Address: <input className="textinput" type="text" name="tokenaddress" value={tokenAddress} onChange={(e) => setTokenAddress(e.target.value)} onClick={() => setShowModal(true)} /><span className='tooltip'>This is the contract address of the token you want to lock up</span></div><br />
    {showModal ? (
      <div className="absolute inset-0 bg-smoke-light w-full h-full flex">
        <div className="text-center scrollbar-thin scrollbar-thumb-red-600 scrollbar-track-gray-800 relative p-8 bg-black text-white w-1/3 m-auto overflow-y-auto h-4/5 flex-col flex">
        <div className="mb-2 text-md font-bold">Warning: Rebasing and Fee on Transfer Tokens WILL NOT WORK</div>
          <div className="mb-2 text-sm">Search by Token Address: {searchAddress} </div>
          <input className="textinput mb-4" type="text" name="searchaddress" value={searchAddress} onChange={(e) => findAndAddToken(e)} />
          {loading && <div className="loader"></div>}
          <span className="text-red mx-auto cursor-pointer absolute top-1 right-6 text-3xl" onClick={() => setShowModal(false)}>x</span>
          <h1 className="text-xl mb-2"> Token List </h1>
          {tokenList && tokenList.map((token, i) => {
            return (token.symbol.toLowerCase().includes(searchAddress.toLowerCase()) || searchAddress.includes('0x'))
            && <div key={i} onClick={() => setTokenAndClose(token.address)} className="mb-4 hover:bg-red-900 cursor-pointer">
              {token.highlight && (<span className="text-red-500">* </span>)}
              {token.symbol + '------------' + token.address} 
              </div>
          })}
        </div>
      </div>
    ) : null}

    <div className="absolute text-center bg-red-900 border-black w-1/3"></div>  </>
  );
}

export default TokenSelect;
